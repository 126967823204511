<template lang="pug">
  .page.page-photo
    .widget.widget--screen-size
      img.page-photo__cover(src="@/assets/img/photos-bg.jpg")
      .page-photo__content
        .page-photo__content-item
          illustration-flower
        p(v-html="$t('photos.title')")
    made-by-invito
</template>

<script>
import IllustrationFlower from '@/components/svg/IllustrationFlower'
import MadeByInvito from '@/components/common/MadeByInvito'

export default {
  name: 'Index',

  components: {
    IllustrationFlower,
    MadeByInvito
  }
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .page-photo {
    color: $color-white;

    &__cover {
      width: auto;
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      position: absolute;
      top: 40%;
      left: 50%;
      width: 30rem;
      transform: translate(-50%, -50%);
      font-size: 1.6rem;
      line-height: 2rem;
      display: flex;
      align-items: stretch;
      flex-direction: column;

      > * {
        opacity: 0;
        animation: fadeUp 1.4s cubic-bezier(0.25, 1, 0.5, 1) .3s forwards;
      }

      &-item {
        text-align: center;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
</style>
